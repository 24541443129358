<template>
  <div :style="pageStyle" class="home">
    <div
      v-if="userInfoSetting"
      class="mine-top flex align-items-c"
      :style="{
        marginTop: userInfoSetting.top_padding / 75 + 'rem',
        padding: '0 ' + userInfoSetting.left_padding / 75 + 'rem',
      }"
      @click="goPageAction('/users/edit')"
    >
      <div
        class="mine-avatar"
        :style="{
          width: userInfoSetting.avatar_width / 75 + 'rem',
          height: userInfoSetting.avatar_height / 75 + 'rem',
        }"
      >
        <img :src="userAvatar" alt="" />
      </div>
      <div
        class="mine-uname flex-one"
        :style="{
          marginLeft: userInfoSetting.uname_margin_left / 75 + 'rem',
        }"
      >
        <div
          class="uname"
          :style="{
            fontSize: userInfoSetting.uname_size / 75 + 'rem',
            color: userInfoSetting.uname_color,
            fontWeight: userInfoSetting.uname_style,
          }"
        >
          {{ userName }}
        </div>
        <div
          v-if="userInfoSetting.open_level"
          class="progress"
          :style="{
            marginTop: userInfoSetting.progress_margin_top / 75 + 'rem',
          }"
        >
          <div
            class="bg"
            :style="{ background: userInfoSetting.progress_color }"
          />
          <div
            class="p"
            :style="{ background: userInfoSetting.progress_color }"
          />
        </div>
        <div
          v-if="userInfoSetting.open_level"
          class="progress-txt"
          :style="{
            marginTop: userInfoSetting.progress_txt_margin_top / 75 + 'rem',
            fontSize: userInfoSetting.progress_txt_size / 75 + 'rem',
            color: userInfoSetting.progress_txt_color,
            fontWeight: userInfoSetting.progress_txt_style,
          }"
        >
          <!-- 成长值 388/1500 升级LV3 -->
        </div>
      </div>
      <div class="user-setting">
        <img :src="userInfoSetting.settingImg" alt="" />
      </div>
    </div>
    <template v-for="(item, index) in mineContent" :key="index">
      <template v-if="item.name === 'mine_nav'">
        <div style="width: 10rem; overflow: hidden">
          <MineNav :content="item.content" :facade="item.facade" />
        </div>
      </template>
      <template v-else-if="item.name === 'banner'">
        <div style="width: 10rem; overflow: hidden">
          <Banner :content="item.content" :facade="item.facade" />
        </div>
      </template>
      <template v-else-if="item.name === 'title'">
        <div style="width: 10rem; overflow: hidden">
          <Title :content="item.content" :facade="item.facade" />
        </div>
      </template>
      <template v-else-if="item.name === 'blank'">
        <div style="width: 10rem; overflow: hidden">
          <Blank :content="item.content" :facade="item.facade" />
        </div>
      </template>
      <template v-else-if="item.name === 'rubik'">
        <div style="width: 10rem; overflow: hidden">
          <Rubik :content="item.content" :facade="item.facade" />
        </div>
      </template>
      <div
        class="video"
        v-else-if="item.name === 'video' && item.content.video.url"
        :style="{ paddingLeft: item.facade.page_padding / 75 + 'rem' }"
      >
        <video
          :style="{
            width: (item.facade.vW || 750) / 75 + 'rem',
            height: (item.facade.vH || 411) / 75 + 'rem',
          }"
          :src="item.content.video.url"
          object-fit="cover"
          :poster="item.content.cover + '!16b9'"
          :loop="item.content.loop"
          :autoplay="item.content.autoplay"
          controls
        ></video>
      </div>
      <template v-else-if="item.name === 'notice'">
        <div style="width: 10rem; overflow: hidden">
          <Notice :content="item.content" :facade="item.facade" />
        </div>
      </template>
    </template>
    <Footer />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, onBeforeMount, computed, onActivated } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
import { getDiyPage } from "@/api/system";
import { userCenterNum, getInfo, orderData, accountInfo } from "@/api/user";
import Banner from "@/components/DiyComponent/Banner";
import Title from "@/components/DiyComponent/Title";
import Blank from "@/components/DiyComponent/Blank";
import Rubik from "@/components/DiyComponent/Rubik";
import Notice from "@/components/DiyComponent/Notice";
import MineNav from "@/components/DiyComponent/MineNav";
export default {
  name: "users_index_new",
  components: {
    Banner,
    Title,
    Blank,
    Rubik,
    Notice,
    MineNav,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const userInfoSetting = ref("");
    const pageStyle = ref("");
    const mineContent = ref("");
    const select_number = ref("");
    // 挂载之前方法 请求一些基本信息
    onBeforeMount(() => {
      store.dispatch("user/getInfo");
      getDiyPage({
        type: "personal_center",
      })
        .then((res) => {
          if (res.data.id) {
            pageStyle.value = getConfigStyle(res.data.config_data);
            const data = res.data.config_data;
            userInfoSetting.value = data.userInfoSetting;
            mineContent.value = res.data.content;
            select_number.value = JSON.stringify(data.select_number);
            loadNumAction();
          }
        })
        .catch((message) => Toast(message));
    });
    let loadOne = false;
    onActivated(() => {
      if (select_number.value && loadOne) {
        loadNumAction();
      }
    });
    const loadNumAction = () => {
      if (select_number.value) {
        let selectnumbr = JSON.parse(select_number.value);
        orderData().then((res) => {
          selectnumbr.forEach((m) => {
            const keyList = m.key.split("-");
            if (keyList[0] && keyList[1]) {
              if (m.type == "order_wait_pay") {
                mineContent.value[keyList[0]].content.data[keyList[1]].value =
                  res.data.noPay;
              } else if (m.type == "order_wait_send") {
                mineContent.value[keyList[0]].content.data[keyList[1]].value =
                  res.data.noPostage;
              } else if (m.type == "order_wait_collect") {
                mineContent.value[keyList[0]].content.data[keyList[1]].value =
                  res.data.noDeliver;
              } else if (m.type == "order_wait_refund") {
                mineContent.value[keyList[0]].content.data[keyList[1]].value =
                  res.data.refund;
              }
            }
          });
          loadOne = true;
        });
        accountInfo().then((res) => {
          selectnumbr.forEach((m) => {
            if (m.type == "my_account") {
              const keyList = m.key.split("-");
              if (keyList[0] && keyList[1]) {
                mineContent.value[keyList[0]].content.data[keyList[1]].value =
                  res.data.now_money;
              }
            }
          });
        });
        getInfo().then((res) => {
          selectnumbr.forEach((m) => {
            const keyList = m.key.split("-");
            if (keyList[0] && keyList[1]) {
              if (m.type == "my_follow_auction") {
                mineContent.value[keyList[0]].content.data[keyList[1]].value =
                  res.data.total_collect_store;
              } else if (m.type == "my_bid_auction") {
                mineContent.value[keyList[0]].content.data[keyList[1]].value =
                  res.data.bid_count;
              } else if (m.type == "my_footprint_auction") {
                mineContent.value[keyList[0]].content.data[keyList[1]].value =
                  res.data.total_visit_product;
              } else if (m.type == "my_integral_auction") {
                mineContent[keyList[0]].content.data[keyList[1]].value =
                  res.data.my_integral_auction || 0;
              }
            }
          });
        });
        // userCenterNum({ data: select_number.value }).then((res) => {
        //   res.data.forEach(m => {
        //     if (m.value) {
        //       const keyList = m.key.split('-');
        //       if (keyList[0] && keyList[1]) {
        //         mineContent.value[keyList[0]].content.data[keyList[1]].value = m.value;
        //       }
        //     }
        //   })
        //   loadOne = true;
        // });
      }
    };

    const userAvatar = computed(() => {
      return store.getters.avatar;
    });
    const userName = computed(() => {
      return store.getters.uname;
    });

    const getConfigStyle = (configData) => {
      let p = `background-color: ${configData.background};`;
      if (configData.bg_image) {
        p +=
          `background-image: url(${configData.bg_image});` +
          `background-repeat: ${
            configData.bg_image_mode === 3 ? "repeat-y" : "no-repeat"
          };` +
          `background-position: ${
            configData.bg_image_mode === 3 || configData.bg_image_mode === 4
              ? "center top"
              : "center"
          };`;
        if (configData.bg_image_mode === 1) {
          p += `background-size: contain;`;
        } else if (configData.bg_image_mode === 2) {
          p += `background-size: cover;`;
        } else if (configData.bg_image_mode === 3) {
          p += `background-size: 10rem;`;
        } else if (configData.bg_image_mode === 4) {
          p += `background-size: 10rem;`;
        }
      }
      return p;
    };

    const goPageAction = (link) => {
      if (link) {
        router.push(link);
      }
    };

    return {
      pageStyle,
      userInfoSetting,
      mineContent,

      userAvatar,
      userName,
      goPageAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 60px;
}
.mine-top {
  .mine-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .progress {
    width: 280px;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    .bg {
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      width: 280px;
      height: 8px;
      z-index: 1;
    }
    .p {
      position: absolute;
      left: 0;
      top: 0;
      width: 100px;
      height: 8px;
      border-radius: 4px;
      z-index: 2;
    }
  }

  .user-setting {
    width: 48px;
    height: 48px;
    img {
      width: 48px;
      height: 48px;
    }
  }
}
</style>
<template>
  <div
    class="navigation"
    :style="{
      'background-color': facade.background,
      backgroundImage: (facade.bg_image ? `url(${facade.bg_image})` : ''),
      backgroundSize: '100% 100%',
      margin: `0 ${facade.page_margin / 75}rem`,
      paddingBottom: (content.style == 1 ? `${32 / 75}rem` : '0'),
      borderRadius: `${facade.page_radius / 75}rem`,
      boxShadow: (facade.page_shadow ? `0 0 ${facade.page_shadow_size / 75}rem ${facade.page_shadow}` : '')
    }"
  >
    <div
      v-if="content.title"
      class="nav-title flex align-items-c justify-b"
    >
      <div :style="{
          fontSize: (facade.title_size / 75) + 'rem',
          fontWeight: facade.title_style,
          color: facade.title_color
        }">
        {{ content.title }}
      </div>
      <div
        v-if="content.right_desc"
        class="flex align-items-c"
        :style="{
          fontSize: (facade.title_right_size / 75) + 'rem',
          fontWeight: facade.title_right_style,
          color: facade.title_right_color
        }"
      >
        <div>{{ content.right_desc }}</div>
        <img
          :style="{ width: (facade.title_right_size / 75) + 'rem', height: (facade.title_right_size / 75) + 'rem' }"
          src="https://artimg2.artart.cn/image/20211214/d13a23794f53952192d6c7defd2a18cc.png"
        >
      </div>
    </div>
    <div class="nav">
      <template v-if="content.style == 2">
        <div
          v-for="(item, index) in navigationData"
          :key="`navline_${index}`"
          class="nav-line item-align-center"
          :class="{ 'no-border': index === 0 }"
          @click="goPageAction"
          :data-link="item.link.link || ''"
        >
          <img
            v-if="item.url"
            :src="item.url"
            alt=""
            :style="{ width: ((parseInt(facade.text_size + 4, 10) / 75) + 'rem'), height: ((parseInt(facade.text_size + 4, 10) / 75) + 'rem'), marginRight: `${5 / 75}rem` }"
          >
          <div :style="{
              'color': facade.text_color,
              'font-weight': (facade.text_style),
              'font-size': (facade.text_size / 75) + 'rem',
            }">{{ item.title }}</div>
          <div
            class="flex-one align-r"
            :style="{
              color: facade.vice_text_color,
              fontSize: (facade.vice_text_size / 75) + 'rem',
              fontWeight: facade.vice_text_style,
            }"
          ><template v-if="item.value">{{ item.right_title ? item.right_title.replace(/\%s/g, formatPriceNumber(item.value, 2)) : '' }}</template></div>
          <img
            :style="{ width: (facade.vice_text_size / 75) + 'rem', height: (facade.vice_text_size / 75) + 'rem' }"
            src="https://artimg2.artart.cn/image/20211214/d13a23794f53952192d6c7defd2a18cc.png"
          >
        </div>
      </template>
      <template v-else>
        <div
          v-for="(m, i) in (facade.line_count == 0 ? 1 : (parseInt(navigationData.length / facade.line_count, 10) + (navigationData.length % facade.line_count === 0 ? 0 : 1)))"
          :key="`line${i}`"
          class="item-align-center"
        >
          <div
            v-for="(item, index) in (facade.line_count == 0 ? navigationData.length : facade.line_count)"
            :key="`line${i}${index}`"
            class="item"
            :class="{ 'flex-one' : facade.line_count != 0 }"
            style="flex-shrink: 0"
            :style="{ marginLeft: (facade.line_count != 0 ? '0' : (facade.item_padding / 75) + 'rem') }"
            @click="goPageAction"
            :data-link="navigationData[i * facade.line_count + index].link.link || ''"
          >
            <template v-if="navigationData[i * facade.line_count + index]">
              <div
                v-if="content.style == 1"
                class="img flex"
                :class="{ 'justify-left': facade.alignment == 'left', 'justify-center': facade.alignment == 'center', 'justify-end': facade.alignment == 'right' }"
              >
                <div
                  v-if="navigationData[i * facade.line_count + index] && navigationData[i * facade.line_count + index].value"
                  class="img-count"
                >{{ formatPriceNumber(navigationData[i * facade.line_count + index].value, 2) }}</div>
                <img
                  class="show-img"
                  v-if="navigationData[i * facade.line_count + index].url"
                  :src="navigationData[i * facade.line_count + index].url"
                >
                <div
                  v-else
                  class="img-view item-flex-center"
                >
                  <img
                    src="https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png"
                    alt=""
                  >
                </div>
              </div>
              <div
                v-else
                class="num"
                :style="{
                  color: facade.vice_text_color,
                  fontSize: (facade.vice_text_size / 75) + 'rem',
                  fontWeight: facade.vice_text_style,
                  textAlign: facade.alignment
                }"
              >{{ navigationData[i * facade.line_count + index].value ? formatPriceNumber(navigationData[i * facade.line_count + index].value, 2) : 0 }}</div>
              <div
                class="txt"
                :style="{
                  'color': facade.text_color,
                  'font-weight': (facade.text_style),
                  'font-size': ((facade.text_size / 75) + 'rem'),
                  lineHeight: '0.48rem',
                  marginTop: (content.style == 1 ? '0.106666rem' : '0'),
                  textAlign: facade.alignment }"
              >{{ navigationData[i * facade.line_count + index].title }}</div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { computed, toRefs } from "vue";
import { formatPriceNumber } from "@/utils/util";

export default {
  props: {
    content: Object,
    facade: Object,
  },
  setup(props) {
    const { content } = toRefs(props);
    const navigationData = computed(() => {
      return content.value.data;
    });

    const router = useRouter();

    const goPageAction = (e) => {
      const item = e.currentTarget.dataset;
      if (item.link) {
        if (item.link.indexOf("pdf?url=") === 0) {
          // const url = item.link.split("=")[1];
          // if (url) {
          //   app.openFiles(url);
          // }
        } else if (item.link == "writeOff") {
          // app.hxkqAction();
        } else if (item.link.indexOf("phone?phone=") === 0) {
          const phone = item.link.split("=")[1];
          window.location.href = "tel://" + phone;
        } else if (item.link == "open_share" || item.link == "contact") {
          //
        } else {
          let jump = "push";
          if (item.jump == 2) {
            jump = "replace";
          }
          router[jump](item.link);
        }
      }
    };

    return { navigationData, goPageAction, formatPriceNumber };
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  overflow-x: auto;
  .nav {
    min-height: 96px;
    .txt {
      text-align: center;
      line-height: 72px;
    }
    .img {
      padding-top: 32px;
      position: relative;

      .img-count {
        width: 32px;
        height: 24px;
        border-radius: 12px;
        background: #f04038;
        text-align: center;
        line-height: 24px;
        font-size: 18px;
        font-weight: 500;
        position: absolute;
        color: #fff;
        left: 50%;
        top: 26px;
        margin-left: 10px;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;

        &.show-img {
          width: 48px;
          height: 48px;
        }
      }
    }
    .img-view {
      width: 48px;
      height: 48px;
      background: #eaeef4;
      img {
        width: 48px;
        height: 48px;
        display: block;
      }
    }
    .item {
      flex-shrink: 0;
    }
  }

  .nav-title {
    padding: 0 30px;
    padding-top: 20px;
  }

  .nav-line {
    margin: 0 30px;
    height: 100px;
    border-top: 2px solid #eeeeee;
  }
  .no-border {
    border: none !important;
  }
}

</style>